'use client';
import React from 'react';
import Image from 'next/image';
import Button from 'components/commercetools-ui/atoms/button';
import Container from 'components/commercetools-ui/atoms/container';
import Link from 'components/commercetools-ui/atoms/link';
import FooterAccount from 'components/commercetools-ui/organisms/footer-account';
import HeaderAccount from 'components/commercetools-ui/organisms/header-account';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import usePagePathWithLocale from 'helpers/hooks/usePagePathWithLocale';
import { mediumDesktop } from 'helpers/utils/screensizes';
import Image404 from 'public/images/error-image.png';

const NotFound = () => {
  const [isMediumDesktopSize] = useMediaQuery(mediumDesktop);
  const { homePagePath } = usePagePathWithLocale();

  const contentHeight = isMediumDesktopSize ? '132px' : '112px';
  return (
    <>
      <HeaderAccount />
      <Container>
        <div className="flex w-full">
          {/* First column error image */}
          <div className="hidden lg:block">
            <Image
              loader={({ src }) => src}
              src={Image404}
              alt={'Page not found'}
              style={{
                width: '550px',
                height: `calc(100vh - ${contentHeight})`,
                objectFit: 'fill',
              }}
            />
          </div>
          {/* Second column Description and Back Button */}
          <div
            className="flex h-full grow flex-col items-center"
            style={{
              paddingTop: '20vh',
              height: `calc(100vh - ${contentHeight})`,
            }}
          >
            <div className="">
              <h1 className="text-32">Strona nie znaleziona</h1>
              <p className="mt-24">Przepraszamy, nie można znaleźć żądanej strony.</p>
              <>
                <Link link={homePagePath}>
                  <Button variant="primary" className="mt-28 px-48 py-12 text-16 font-medium">
                    Wróć na stronę główną
                  </Button>
                </Link>
              </>
            </div>
          </div>
        </div>
      </Container>
      <FooterAccount
        copyright={'Selgros 2024 © wszystkie prawa zastrzeżone'}
        privacy={[
          {
            name: 'Polityka prywatności',
            reference: {
              link: '/selgros24/regulaminy/polityka-prywatnosci/',
              openInNewWindow: false,
              type: 'link',
            },
          },
        ]}
      />
    </>
  );
};

export default NotFound;
