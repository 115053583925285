import React from 'react';
import { useRouter } from 'next/navigation';
import Button from 'components/commercetools-ui/atoms/button';
import Container from 'components/commercetools-ui/atoms/container';
import HeaderLogo from 'components/commercetools-ui/organisms/header/header-logo';
import HomeIcon from 'components/icons/HomeIcon';
import usePagePathWithLocale from 'helpers/hooks/usePagePathWithLocale';

const HeaderAccount: React.FC = () => {
  const router = useRouter();
  const { homePagePath } = usePagePathWithLocale();

  return (
    <header className="relative h-fit w-full border-b-[1.5px] border-neutral-400 bg-white">
      <Container>
        <div aria-label="Top" className="flex w-full items-center justify-between px-16 md:px-24 lg:px-20 xl:px-46">
          <div className="ml-0 flex w-full justify-start gap-12 py-12 xl:gap-24 xl:pb-20 xl:pt-24">
            <Button
              className="self-center"
              variant="home"
              size="home"
              onClick={() => router.push(homePagePath)}
              icon={<HomeIcon />}
            ></Button>
            <HeaderLogo imageClassName="items-center justify-between xl:[&>svg]:h-32 xl:[&>svg]:w-180 flex [&>svg]:h-28 w-161" />
          </div>
        </div>
      </Container>
    </header>
  );
};

export default HeaderAccount;
